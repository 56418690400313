<template>
  <div>
    <h3>Notifications</h3>

    <div class="card" style="height: calc(24rem + 10px)">
      <div class="card-body card-body-scrollable card-body-scrollable-shadow">
        <div class="divide-y mb-4" v-if="loaded && notifications.length > 0">
          <div
            v-for="notification in notifications"
            :key="`notification-${notification.id}`"
            class="row"
          >
            <div class="col-auto align-self-center">
              <span class="avatar">
                <notification-icon
                  :icon="notification.icon"
                ></notification-icon>
              </span>
            </div>
            <div class="col">
              <div>
                {{ notification.message }}
                <a
                  :href="notification.link"
                  class=""
                  :target="notification.linkTarget"
                  v-if="notification.link && notification.linkTarget"
                >
                  <span v-html="notification.linkMessage"></span>
                </a>
                <router-link
                  v-else-if="notification.link"
                  :to="notification.link"
                >
                  <span v-html="notification.linkMessage"></span>
                </router-link>
              </div>
              <div class="text-muted">
                Posted {{ prettyDate(notification.postedDate, new Date()) }} ago
                <span v-if="notification.postedBy">
                  from
                  <img
                    :src="notification.postedByImage"
                    class="rounded-circle"
                    style="max-height: 16px"
                    v-if="notification.postedByImage"
                  />
                  {{ notification.postedBy }}</span
                >
              </div>
            </div>
            <div class="col-auto align-self-center">
              <div class="badge bg-primary"></div>
            </div>
          </div>
        </div>
        <div class="empty" v-else-if="loaded && notifications.length == 0">
          <div class="empty-img">
            <img
              src="@/assets/illustrations/undraw_happy_announcement_re_tsm0.svg"
              alt=""
              height="128"
            />
          </div>
          <p class="empty-title">No notifications yet</p>
          <p class="empty-subtitle text-muted">
            We'll let you know important updates about your company's directory
            listing here.
          </p>
        </div>
        <div class="empty" v-else>
          <p class="empty-title">Loading notifications...</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationsService from "../../../services/NotificationsService";
import { formatDistance } from "date-fns";
import NotificationIcon from "./NotificationIcon.vue";

export default {
  name: "NotificationPane",
  components: {
    NotificationIcon,
  },
  data() {
    return {
      notifications: [],
      loaded: false,
    };
  },
  methods: {
    async loadNotifications() {
      this.loaded = false;
      this.notifications = await NotificationsService.getNotifications(
        this.$auth.activeCompany
      );
      this.loaded = true;
    },
    prettyDate(date, related) {
      return formatDistance(date, related);
    },
  },
  async mounted() {
    await this.loadNotifications();
  },
};
</script>
