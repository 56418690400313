<template>
  <div class="col-md-6 col-lg-4">
    <stat-card
      :options="options"
      :series="series"
      :showing="showing"
      :header="'Actions'"
      :stat="totalConversions"
      :rawStat="totalConversionsRaw"
      @show="show"
      :description="'Clicks to your phone number and website from your profile page'"
    ></stat-card>
  </div>
</template>

<script>
import { format } from "date-fns";

import StatCard from "../../StatCard/StatCard.vue";
import AnalyticsService from "@/services/AnalyticsService.js";

export default {
  components: { StatCard },
  data: () => {
    return {
      change: 0,
      showing: "",
      totalConversions: "Loading...",
      totalConversionsRaw: 0,
      options: {
        chart: {
          type: "area",
          fontFamily: "inherit",
          height: 40.0,
          sparkline: {
            enabled: true,
          },
          animations: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          opacity: 0.16,
          type: "solid",
        },
        stroke: {
          width: 2,
          lineCap: "round",
          curve: "smooth",
        },
        grid: {
          strokeDashArray: 4,
        },
        xaxis: {
          labels: {
            padding: 0,
            formatter: (d) => format(d, "LLLL dd yyyy"),
          },
          tooltip: {
            enabled: false,
          },
          axisBorder: {
            show: false,
          },
          type: "datetime",
        },
        yaxis: {
          labels: {
            padding: 4,
          },
        },
        labels: [],
        colors: ["#2f8dde"],
        legend: {
          show: false,
        },
      },

      series: [],
    };
  },
  methods: {
    async setupMonthly() {
      this.options.labels.splice(0);
      this.series.splice(0);
      let data = await AnalyticsService.getClicksAndViews(
        this.$auth.activeCompany
      );
      data.current.monthly.months.forEach((i) =>
        this.options.labels.push(i.getTime())
      );
      let series = {
        name: "Conversions",
        data: data.current.monthly.month_stats.map((i) => i.companyClicks),
      };
      this.series.push(series);
      let totalConversions = data.current.daily.reduce(
        (t, c) => t + c.companyClicks,
        0
      );
      this.totalConversions = totalConversions.toLocaleString();
      this.totalConversionsRaw = totalConversions;
      this.showing = "Last 12 Months";
    },
    async setupDaily() {
      this.options.labels.splice(0);
      this.series.splice(0);
      let data = await AnalyticsService.getClicksAndViews(
        this.$auth.activeCompany
      );

      let last30days = data.current.daily.slice(-30);
      last30days.forEach((i) => this.options.labels.push(i.date));

      let series = {
        name: "Actions",
        data: last30days.map((i) => i.companyClicks),
      };
      this.series.push(series);

      let totalViews = last30days.reduce((t, c) => t + c.companyClicks, 0);
      this.totalConversions = totalViews.toLocaleString();
      this.totalConversionsRaw = totalViews;
      this.showing = "Last 30 Days";
    },
    show(length) {
      if (length === "month") {
        this.setupDaily();
      } else {
        this.setupMonthly();
      }
    },
  },
  async mounted() {
    if (this.$route.query.show) {
      this.show(this.$route.query.show);
    } else {
      this.setupMonthly();
    }
    // let previousConversions = data.previous.reduce(
    //   (t, c) => t + c.companyClicks,
    //   0
    // );

    // let increase = totalConversions - previousConversions;
    // increase /= previousConversions;
    // let change = increase * 100;
    // this.change = change;
  },
};
</script>
