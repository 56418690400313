<template>
  <div class="col-md-6 col-lg-4">
    <stat-card
      :options="options"
      :series="series"
      :header="'Listing Views'"
      :showing="showing"
      :stat="totalViews"
      :rawStat="totalViewsRaw"
      @show="show"
      :description="'All views of your listing on directory sites'"
    ></stat-card>
  </div>
</template>

<script>
import { format } from "date-fns";

import StatCard from "../../StatCard/StatCard.vue";
import AnalyticsService from "@/services/AnalyticsService.js";

export default {
  components: { StatCard },
  data: () => {
    return {
      showing: "",
      change: 0,
      totalViews: "Loading...",
      totalViewsRaw: 0,
      maxValue: 0,
      options: {
        chart: {
          type: "area",
          fontFamily: "inherit",
          height: 40.0,
          sparkline: {
            enabled: true,
          },
          animations: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          opacity: 0.16,
          type: "solid",
        },
        stroke: {
          width: 2,
          lineCap: "round",
          curve: "smooth",
        },
        grid: {
          strokeDashArray: 4,
        },
        xaxis: {
          labels: {
            padding: 0,
            formatter: (d) => format(d, "LLLL dd yyyy"),
          },
          tooltip: {
            enabled: false,
          },
          axisBorder: {
            show: false,
          },
          type: "datetime",
        },
        yaxis: {
          labels: {
            padding: 4,
          },
          min: 0,
          max: function (maxValue) {
            if (maxValue == 0) {
              return maxValue;
            } else {
              let numOfDigits = maxValue.toString().length;
              let length = 10;
              for (let i = 1; i < numOfDigits - 1; i++) {
                length *= 10;
              }
              let val = Math.ceil(maxValue / length) * length + length / 2;
              return val;
            }
          },
        },
        labels: [],
        colors: ["#6e66de"],
        legend: {
          show: false,
        },
      },
      series: [],
    };
  },
  methods: {
    async setupMonthly() {
      this.options.labels.splice(0);
      this.series.splice(0);

      let data = await AnalyticsService.getClicksAndViews(
        this.$auth.activeCompany
      );
      data.current.monthly.months.forEach((i) =>
        this.options.labels.push(i.getTime())
      );

      let series = {
        name: "Listing Views",
        data: data.current.monthly.month_stats.map((i) => i.impressions),
      };
      this.series.push(series);

      let totalViews = data.current.daily.reduce(
        (t, c) => t + c.impressions,
        0
      );
      this.totalViews = totalViews.toLocaleString();
      this.totalViewsRaw = totalViews;
      this.showing = "Last 12 Months";

      this.maxValue = Math.max.apply(
        Math,
        data.current.monthly.month_stats.map((i) => i.impressions)
      );
      this.options.yaxis.max(this.maxValue);
    },

    async setupDaily() {
      this.options.labels.splice(0);
      this.series.splice(0);
      let data = await AnalyticsService.getClicksAndViews(
        this.$auth.activeCompany
      );

      let last30days = data.current.daily.slice(-30);
      last30days.forEach((i) => this.options.labels.push(i.date));

      let series = {
        name: "Listing Views",
        data: last30days.map((i) => i.impressions),
      };
      this.series.push(series);

      let totalViews = last30days.reduce((t, c) => t + c.impressions, 0);
      this.totalViews = totalViews.toLocaleString();
      this.totalViewsRaw = totalViews;
      this.showing = "Last 30 Days";

      this.maxValue = Math.max.apply(
        Math,
        last30days.map((i) => i.impressions)
      );
      this.options.yaxis.max(this.maxValue);
    },
    show(length) {
      if (length === "month") {
        this.setupDaily();
      } else {
        this.setupMonthly();
      }
    },
  },
  async mounted() {
    if (this.$route.query.show) {
      this.show(this.$route.query.show);
    } else {
      await this.setupMonthly();
    }
    // let previousViews = data.previous.reduce((t, c) => t + c.impressions, 0);

    // let increase = totalViews - previousViews;
    // increase /= previousViews;
    // let change = increase * 100;
    // this.change = change;
    // console.log(this.change);
  },
};
</script>
