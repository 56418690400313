<template>
  <div>
    <h3><br /></h3>

    <div
      class="d-flex flex-column"
      style="max-height: calc(10px + 24rem); overflow-y: auto"
    >
      <div class="card mb-3" v-if="!$auth.isFeatured">
        <div class="row row-0">
          <div
            class="col-3 order-md-last d-flex align-items-center justify-content-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-lg text-blue icon-tabler icon-tabler-businessplan"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <ellipse cx="16" cy="6" rx="5" ry="3"></ellipse>
              <path d="M11 6v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4"></path>
              <path d="M11 10v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4"></path>
              <path d="M11 14v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4"></path>
              <path
                d="M7 9h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5"
              ></path>
              <path d="M5 15v1m0 -8v1"></path>
            </svg>
          </div>
          <div class="col">
            <div class="card-body">
              <h3 class="card-title">Upgrade to a featured listing</h3>
              <p>
                Start reaching more customers browsing your categories. A
                featured listing will put your company at the top of the page
                for your top categories.
                <router-link to="/upgrade">
                  Learn more about a featured listing &raquo;
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-3">
        <div class="row row-0">
          <div
            class="col-3 order-md-last d-flex align-items-center justify-content-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-lg text-blue icon-tabler icon-tabler-stars"
              width="64"
              height="64"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path
                d="M17.8 19.817l-2.172 1.138a0.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a0.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a0.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a0.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a0.39 .39 0 0 1 -.567 .411l-2.172 -1.138z"
              ></path>
              <path
                d="M6.2 19.817l-2.172 1.138a0.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a0.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a0.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a0.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a0.39 .39 0 0 1 -.567 .411l-2.172 -1.138z"
              ></path>
              <path
                d="M12 9.817l-2.172 1.138a0.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a0.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a0.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a0.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a0.39 .39 0 0 1 -.567 .411l-2.172 -1.138z"
              ></path>
            </svg>
          </div>
          <div class="col">
            <div class="card-body">
              <h3 class="card-title">Reputation management tools</h3>
              <p>
                Adding more reviews will help customers when they are in the
                decision-making phase of the buyer journey, and also help your
                listing's search engine performance.
                <router-link to="/reputation">
                  Start getting reviews now &raquo;
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-3" v-if="$auth.isFeatured">
        <div class="row row-0">
          <div
            class="col-3 order-md-last d-flex align-items-center justify-content-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-lg text-blue icon-tabler icon-tabler-report-analytics"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path
                d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"
              ></path>
              <rect x="9" y="3" width="6" height="4" rx="2"></rect>
              <path d="M9 17v-5"></path>
              <path d="M12 17v-1"></path>
              <path d="M15 17v-3"></path>
            </svg>
          </div>
          <div class="col">
            <div class="card-body">
              <h3 class="card-title">Detailed listing performance report</h3>
              <p>
                Get further insights into your listing's performance broken down
                by site and category.
                <router-link to="/reports/performance-report">
                  View report &raquo;
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Improvements",
};
</script>
