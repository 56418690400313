import { parseISO, format, startOfMonth, subYears } from "date-fns";
import APIService from "./APIService";
import { getInstance } from "../auth/auth";

const http = APIService.http;

export default {
  async getClicksAndViews(company) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/Reports/ProfileAnalytics",
      company,
      authHeaders
    );
    let unf = res.data;
    let complete = {};
    ["current", "previous"].forEach((i) => {
      let data = unf[i];
      let months = [
        "January",
        "Febraury",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "October",
        "November",
        "December",
      ];
      let this_month = new Date().getMonth();
      let correct_months = [];
      for (var j = this_month + 1; j < months.length + 1; j++) {
        correct_months.push(months[j]);
      }
      for (j = 0; j < this_month; j++) {
        correct_months.push(months[j]);
      }
      console.log(correct_months);
      data = data.map((i) => {
        i["date_parsed"] = parseISO(i["date"]);
        i["month_index"] = i["date_parsed"].getMonth();
        i["month"] = months[i["month_index"]];
        i["date"] = format(parseISO(i["date"]), "MM/dd/yyyy");
        return i;
      });
      let monthly = [];
      let month_first_days = [];
      correct_months.forEach((m) => {
        let month_data = data.filter((i) => i.month == m);
        month_first_days.push(
          months.indexOf(m) > this_month
            ? subYears(startOfMonth(new Date().setMonth(months.indexOf(m))), 1)
            : startOfMonth(new Date().setMonth(months.indexOf(m)))
        );
        monthly.push({
          actions: month_data.reduce((t, i) => t + i.actions, 0),
          companyClicks: month_data.reduce((t, i) => t + i.companyClicks, 0),
          impressions: month_data.reduce((t, i) => t + i.impressions, 0),
          profileViews: month_data.reduce((t, i) => t + i.profileViews, 0),
        });
      });
      complete[i] = {
        monthly: {
          months: month_first_days,
          month_stats: monthly,
        },
        daily: data,
      };
    });
    return complete;
  },
  async getLeads(company) {
    let res = await http.post("api/Reports/Leads", company);
    let data = res.data;
    data = data.map((i) => {
      i["date"] = format(parseISO(i["date"]), "MM/dd/yyyy");
      return i;
    });
    return data;
  },
};
