<template>
  <div class="home page-wrapper">
    <div class="container-xl">
      <!-- Page title -->
      <div class="page-header d-print-none">
        <div class="row align-items-center">
          <div class="col-md-9">
            <!-- Page pre-title -->
            <div class="page-pretitle">Dashboard</div>
            <h2 class="page-title">{{ $auth.activeCompany.companyName }}</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="container-xl">
        <div
          class="row row-deck row-cards mb-3"
          v-if="canAccess('dashboard-report', $auth.highestPlan)"
        >
          <impressions></impressions>
          <profile-views></profile-views>
          <profile-clicks></profile-clicks>
        </div>
        <div class="row teaser-graphs mb-3" v-else>
          <div
            class="col d-flex flex-column align-items-center justify-content-center"
          >
            <div class="card">
              <div
                class="card-body d-flex flex-column align-items-center justify-content-center"
              >
                <div class="mb-2">
                  <strong>
                    Unlock detailed statistics with an upgraded listing.
                  </strong>
                </div>
                <div>
                  <router-link to="/upgrade" class="btn btn-primary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-lock-open"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <rect x="5" y="11" width="14" height="10" rx="2"></rect>
                      <circle cx="12" cy="16" r="1"></circle>
                      <path d="M8 11v-5a4 4 0 0 1 8 0"></path>
                    </svg>
                    Upgrade
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <notification-pane></notification-pane>
          </div>
          <div class="col-md-6">
            <improvements></improvements>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.teaser-graphs {
  height: 150px;
  background: center / contain no-repeat url("../assets/teaser.png");
}
</style>

<script>
import Impressions from "../components/Dashboard/Cards/Impressions.vue";
import ProfileViews from "../components/Dashboard/Cards/ProfileViews.vue";
import ProfileClicks from "../components/Dashboard/Cards/ProfileClicks.vue";
import NotificationPane from "../components/Dashboard/Notifications/NotificationPane.vue";
import Improvements from "../components/Dashboard/Improvements/Improvements.vue";
import UpsellService from "../services/UpsellService";
import APIService from "../services/APIService";

import TrackingService from "../services/TrackingService";

export default {
  name: "Dashboard",
  components: {
    Impressions,
    ProfileViews,
    ProfileClicks,
    NotificationPane,
    Improvements,
  },
  data: () => {
    return {
      mb_base_url: APIService.mb_base_url,
    };
  },
  methods: {
    canAccess(feature, plan) {
      return UpsellService.planIncludes(feature, plan);
    },
  },
  mounted() {
    TrackingService.track("viewed dashboard");
  },
};
</script>
