<template>
  <div class="card">
    <template v-if="show_stat">
      <div class="card-body" style="position: relative">
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center">
            <span class="subheader">{{ header }}</span>
            <span
              class="badge bg-blue-lt"
              style="margin-left: 5px"
              v-if="description"
              v-b-tooltip.hover
              :title="description"
            >
              ?
            </span>
          </div>
          <div class="ms-auto position-relative lh-1">
            <div class="dropdown">
              <a href="#" class="dropdown-toggle" data-bs-toggle="dropdown">
                <span class="text-muted small subheader">
                  {{ showing }}
                </span>
              </a>
              <div class="dropdown-menu dropdown-menu-end z-highest" style="">
                <a
                  class="dropdown-item"
                  @click.prevent="$emit('show', 'year')"
                  href="#"
                >
                  Show last 12 months
                </a>
                <a
                  class="dropdown-item"
                  @click.prevent="$emit('show', 'month')"
                  href="#"
                >
                  Show last 30 days
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-baseline">
          <div class="h1 mb-0 me-2">{{ stat }}</div>
          <div class="me-auto">
            <span
              v-if="change"
              :class="[
                change > 0 ? 'text-green' : 'text-red',
                'd-inline-flex',
                'align-items-center',
                'lh-1',
              ]"
            >
              {{ prettyChange }}%
              <!-- Download SVG icon from http://tabler-icons.io/i/trending-up -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon ms-1"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                v-if="change > 0"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <polyline points="3 17 9 11 13 15 21 7"></polyline>
                <polyline points="14 7 21 7 21 14"></polyline>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-trending-down"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                v-else
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <polyline points="3 7 9 13 13 9 21 17"></polyline>
                <polyline points="21 10 21 17 14 17"></polyline>
              </svg>
            </span>
          </div>
        </div>
      </div>
      <div id="chart-active-users" class="chart-sm" style="min-height: 60px">
        <apexchart height="60" :options="options" :series="series"></apexchart>
      </div>
    </template>
    <template v-else>
      <div class="card-body">
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center">
            <span class="subheader">{{ header }}</span>
            <span
              class="badge bg-blue-lt"
              style="margin-left: 5px"
              v-if="description"
              v-b-tooltip.hover
              :title="description"
            >
              ?
            </span>
          </div>

          <div class="ms-auto position-relative lh-1">
            <div class="dropdown">
              <a href="#" class="dropdown-toggle" data-bs-toggle="dropdown">
                <span class="text-muted small subheader">
                  {{ showing }}
                </span>
              </a>
              <div class="dropdown-menu dropdown-menu-end z-highest" style="">
                <a
                  class="dropdown-item"
                  @click.prevent="$emit('show', 'year')"
                  href="#"
                >
                  Show last 12 months
                </a>
                <a
                  class="dropdown-item"
                  @click.prevent="$emit('show', 'month')"
                  href="#"
                >
                  Show last 30 days
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-baseline mt-2">
          <span class="text-muted">
            No data exists for the selected view.
          </span>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.z-highest {
  z-index: 1000000;
}
</style>

<script>
export default {
  props: {
    header: String,
    options: Object,
    series: Array,
    stat: String,
    rawStat: Number,
    change: Number,
    description: String,
    showing: String,
  },

  computed: {
    show_stat() {
      if (this.stat === "Loading...") {
        return true;
      }
      if (this.rawStat) {
        return !isNaN(this.rawStat) && this.rawStat != Infinity;
      } else {
        return !isNaN(this.stat) && this.stat > 0;
      }
    },
    prettyChange: function () {
      return Math.round(this.change * 100) / 100;
    },
  },
};
</script>
